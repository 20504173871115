import { ReactNode } from 'react';
import DashboardObjectConfigSidebar from 'src/components/Dashboards/DashboardView/DashboardObjectConfigSidebar';
import { useUpdateDashboardObjectConfig } from 'src/dashboard/dashboardController';
import {
  useCloseDashboardFlyout,
  useEditingDashboardObject,
  useSelectedDashboard
} from 'src/state/dashboard';

export function ObjectConfigFlyout(): ReactNode {
  const dashboard = useSelectedDashboard();
  const editingObject = useEditingDashboardObject();
  const updateDashboardObjectConfig = useUpdateDashboardObjectConfig();
  const closeDashboardFlyout = useCloseDashboardFlyout();

  if (!dashboard) {
    console.error("Can't render ObjectConfig. No dashboard selected");
    return null;
  }

  return (
    <DashboardObjectConfigSidebar
      dashboard={dashboard}
      dashboardObject={editingObject ?? undefined}
      onClose={closeDashboardFlyout}
      updateDashboardObjectConfig={updateDashboardObjectConfig}
    />
  );
}
