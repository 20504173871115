import { createToast } from '@clickhouse/click-ui';
import { useSetAtom } from 'jotai';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import set from 'lodash/set';
import { useCallback } from 'react';
import { Dashboard, DashboardConfig, DashboardFilterConfig, DashboardObjectConfig } from 'shared/src/types/dashboard';
import { useCurrentInstanceId } from 'src/instance/instanceController';
import { useApiClient } from 'src/lib/controlPlane/client';
import { cleanFilterConfig, findFilterableFields } from 'src/lib/dashboard/utils';
import {
  dashboardsAtom,
  useDashboards,
  useEditingDashboardObject,
  useSelectedDashboard,
  useSetDashboardFlyoutOpen,
  useSetDashboardFlyoutType,
  useSetEditingObjectId,
  useUpdateDashboard
} from 'src/state/dashboard';

export function useCreateDashboard(): (name: string) => Promise<Dashboard> {
  const apiClient = useApiClient();
  const serviceId = useCurrentInstanceId() || '';
  const setDashboards = useSetAtom(dashboardsAtom);

  const createDashboard = async (name: string): Promise<Dashboard> => {
    const dashboard = await apiClient.dashboard.createDashboard({ name, serviceId });
    setDashboards((dashboards: Record<string, Dashboard>): Record<string, Dashboard> => {
      return {
        ...dashboards,
        [dashboard.id]: dashboard
      };
    });
    return dashboard;
  };

  return createDashboard;
}

export function useFetchDashboards(): () => Promise<Record<string, Dashboard>> {
  const apiClient = useApiClient();
  const serviceId = useCurrentInstanceId() || '';
  const setDashboards = useSetAtom(dashboardsAtom);

  return useCallback(async (): Promise<Record<string, Dashboard>> => {
    const dashboards = await apiClient.dashboard.listDashboards({ serviceId });
    setDashboards(dashboards);
    return dashboards;
  }, [apiClient, serviceId, setDashboards]);
}

export const useFetchDashboard = (): ((dashboardId: string) => Promise<Dashboard>) => {
  const apiClient = useApiClient();
  const serviceId = useCurrentInstanceId() || '';

  return useCallback(
    async (dashboardId: string): Promise<Dashboard> => {
      const dashboard = await apiClient.dashboard.getDashboard({
        dashboardId,
        serviceId
      });

      return dashboard;
    },
    [apiClient, serviceId]
  );
};

export const useDeleteDashboard = (): ((dashboardId: string) => Promise<boolean>) => {
  const apiClient = useApiClient();
  const serviceId = useCurrentInstanceId() || '';

  return useCallback(
    async (dashboardId: string): Promise<boolean> => {
      const successful = await apiClient.dashboard.deleteDashboard({
        dashboardId,
        serviceId
      });

      return successful;
    },
    [apiClient, serviceId]
  );
};

export const useUpdateDashboardName = (): ((dashboardId: string, name: string) => Promise<Dashboard>) => {
  const apiClient = useApiClient();
  const dashboards = useDashboards();
  const serviceId = useCurrentInstanceId() || '';
  const updateDashboard = useUpdateDashboard();

  return useCallback(
    async (dashboardId: string, name: string): Promise<Dashboard> => {
      const currentName = dashboards[dashboardId].name;
      try {
        // optimistic update
        updateDashboard(dashboardId, { name });
        const dashboard = await apiClient.dashboard.updateDashboard({
          dashboard: {
            name
          },
          dashboardId,
          serviceId
        });

        return dashboard;
      } catch (error) {
        if (error instanceof Error) {
          createToast({
            title: 'Error Updating Dashboard Name',
            description: error.message,
            type: 'danger'
          });
        }
        // rollback optimistic update
        updateDashboard(dashboardId, { name: currentName });
        throw error;
      }
    },
    [apiClient, dashboards, serviceId, updateDashboard]
  );
};

export function useUpdateDashboardConfig(): (dashboardId: string, config: DashboardConfig) => Promise<DashboardConfig> {
  const apiClient = useApiClient();
  const dashboards = useDashboards();
  const serviceId = useCurrentInstanceId() || '';
  const updateDashboard = useUpdateDashboard();

  return useCallback(
    async (dashboardId: string, config: DashboardConfig): Promise<DashboardConfig> => {
      const currentConfig = dashboards[dashboardId].config;

      try {
        const cleanedConfig = cleanFilterConfig(config);
        updateDashboard(dashboardId, { config: cleanedConfig }); // optimistic update
        const dashboard = await apiClient.dashboard.updateDashboard({
          dashboard: {
            config: cleanedConfig
          },
          dashboardId,
          serviceId
        });
        updateDashboard(dashboardId, { config: dashboard.config }); // confirm optimistic update

        return config;
      } catch (e) {
        if (e instanceof Error) {
          createToast({
            title: 'Error Updating Dashboard',
            description: e.message,
            type: 'danger'
          });
        }
        updateDashboard(dashboardId, { config: currentConfig }); // rollback optimistic update
        throw e;
      }
    },
    [apiClient, dashboards, serviceId, updateDashboard]
  );
}

export function useOpenEditDashboardObjectFlyout(): (dashboardObjectId: string) => void {
  const setEditingObjectId = useSetEditingObjectId();
  const setFlyoutOpen = useSetDashboardFlyoutOpen();
  const setFlyoutType = useSetDashboardFlyoutType();

  return (dashboardObjectId: string): void => {
    setEditingObjectId(dashboardObjectId);
    setFlyoutOpen(true);
    setFlyoutType('objectConfig');
  };
}

export function useOpenFilterConfigObjectFlyout(): () => void {
  const setEditingObjectId = useSetEditingObjectId();
  const setFlyoutOpen = useSetDashboardFlyoutOpen();
  const setFlyoutType = useSetDashboardFlyoutType();

  return (): void => {
    setEditingObjectId(null);
    setFlyoutOpen(true);
    setFlyoutType('filterConfig');
  };
}

export type DashboardConfigUpdateFunc = (config: DashboardConfig) => DashboardConfig;
export type UpdateDashboardFilterConfigFunc = (key: string, config: DashboardFilterConfig) => Promise<void>;

export function buildFilterConfigUpdater(
  filterConfig: Record<string, DashboardFilterConfig>
): DashboardConfigUpdateFunc {
  return (config: DashboardConfig) => {
    merge(config.dashboardFilters.config, filterConfig);
    return config;
  };
}

export function buildObjectConfigUpdater(
  objectId: string,
  objectConfig: DashboardObjectConfig
): DashboardConfigUpdateFunc {
  return (config: DashboardConfig) => {
    set(config, `dashboardObjects.${objectId}.config`, objectConfig);
    return config;
  };
}

export type UpdateDashboardObjectConfig = (config: DashboardObjectConfig) => Promise<DashboardObjectConfig | null>;

export const useUpdateDashboardObjectConfig = (): UpdateDashboardObjectConfig => {
  const dashboard = useSelectedDashboard();
  const updateDashboardConfig = useUpdateDashboardConfig();
  const editingObject = useEditingDashboardObject();

  return async (config: DashboardObjectConfig): Promise<DashboardObjectConfig | null> => {
    if (!editingObject || !dashboard) {
      return null;
    }

    const newConfig = cloneDeep(dashboard.config);
    buildObjectConfigUpdater(editingObject.id, config)(newConfig);

    const newDashboardConfig = await updateDashboardConfig(dashboard.id, newConfig);
    return newDashboardConfig.dashboardObjects[editingObject.id].config;
  };
};

// use when multiple changes to the config at a time are needed
// updates is an array of updater functions that take in a config object and returns
// the new config object with the update applied
// currently we have `buildFilterConfigUpdater` and `buildObjectConfigUpdater` as curried helper functions
// that are compatible with this updates array
export const useComposeUpdateDashboardConfig = (): ((
  config: DashboardConfig,
  updates: DashboardConfigUpdateFunc[]
) => Promise<DashboardConfig | null>) => {
  const dashboard = useSelectedDashboard();
  const updateDashboardConfig = useUpdateDashboardConfig();

  return async (config: DashboardConfig, updates: DashboardConfigUpdateFunc[]) => {
    if (!dashboard) {
      return null;
    }

    const newConfig = updates.reduce((currentConfig, update) => {
      return update(currentConfig);
    }, cloneDeep(config));

    return updateDashboardConfig(dashboard.id, newConfig);
  };
};
