import {
  Container,
  Icon,
  Select,
  Separator,
  Spacer,
  Switch,
  Table,
  TextField,
  useCUITheme
} from '@clickhouse/click-ui';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { ChartConfig } from 'src/lib/chart/types';
import { chartMap, ChartSchemaData } from 'src/lib/chart/config';
import { ExecuteQueryResult } from 'shared/src/queryEndpoints/protocol';
import styled from 'styled-components';

const ColorBox = styled(Container)<{ $backgroundColor: string }>`
  ${({ $backgroundColor }): string => `background-color: ${$backgroundColor}`};
  border-radius: 4px;
`;

type Axis = 'xAxis' | 'yAxis';

interface ColumnSelectorProps {
  chartConfig: ChartConfig;
  chartTypeSchema: ChartSchemaData;
  queryResults: ExecuteQueryResult;
  updateChart: (config: ChartConfig) => void;
}

const ColumnSelector = ({
  chartConfig,
  chartTypeSchema,
  queryResults,
  updateChart
}: ColumnSelectorProps): ReactNode => {
  const cuiTheme = useCUITheme();
  const chartColors = [
    cuiTheme.global.color.chart.bars['orange'],
    cuiTheme.global.color.chart.bars['fuchsia'],
    cuiTheme.global.color.chart.bars['blue'],
    cuiTheme.global.color.chart.bars['violet'],
    cuiTheme.global.color.chart.bars['teal'],
    cuiTheme.global.color.chart.bars['green']
  ];

  if (!queryResults) {
    return null;
  }

  const addAxisConfig = (column: string, axis: Axis): void => {
    const newConfig = { ...chartConfig };

    if (chartTypeSchema[axis]?.type === 'column') {
      newConfig[axis] = column;
    }

    if (chartTypeSchema[axis]?.type === 'columnArray') {
      if (!newConfig[axis]) {
        newConfig[axis] = [];
      }

      if (Array.isArray(newConfig[axis])) {
        newConfig[axis].push({
          column,
          color: chartColors[newConfig[axis].length % chartColors.length]
        });
      }
    }

    updateChart(newConfig);
  };

  const removeAxisConfig = (column: string, axis: Axis): void => {
    const newConfig = { ...chartConfig };

    if (chartTypeSchema[axis]?.type === 'column') {
      newConfig[axis] = null;
    }

    if (chartTypeSchema[axis]?.type === 'columnArray') {
      newConfig[axis] = (
        newConfig[axis] as Array<Record<string, string>>
      ).filter((axisConfig) => {
        return axisConfig.column !== column;
      });
    }

    updateChart(newConfig);
  };

  const resultsColumns = Object.keys(queryResults[0]);

  return (
    <Container orientation="vertical">
      <Table
        headers={[
          { label: 'Column' },
          { label: '', width: '12%' },
          { label: 'X', width: '15%' },
          { label: 'Y', width: '15%' }
        ]}
        rows={resultsColumns.map((column) => {
          const isXAxisSelected = (chartConfig.xAxis ?? '') === column;
          const isYAxisSelected =
            Array.isArray(chartConfig.yAxis) &&
            (chartConfig.yAxis as Array<Record<string, string>>).some(
              (axisConfig) => {
                return axisConfig.column === column;
              }
            );

          let chartColor = chartColors[0];
          if (Array.isArray(chartConfig?.yAxis)) {
            chartColor =
              (chartConfig.yAxis as Array<Record<string, string>>).find(
                (axisConfig) => axisConfig.column === column
              )?.color ?? chartColors[0];
          }
          return {
            id: column,
            items: [
              {
                label: <Container>{column}</Container>
              },
              {
                label: isYAxisSelected ? (
                  <ColorBox
                    $backgroundColor={chartColor}
                    fillWidth={false}
                    padding="md"
                  />
                ) : (
                  ''
                )
              },
              {
                label: (
                  <Switch
                    checked={isXAxisSelected}
                    onCheckedChange={(isChecked: boolean) => {
                      if (isChecked) {
                        addAxisConfig(column, 'xAxis');
                      } else {
                        removeAxisConfig(column, 'xAxis');
                      }
                    }}
                  />
                )
              },
              {
                label: (
                  <Switch
                    checked={isYAxisSelected}
                    onCheckedChange={(isChecked: boolean) => {
                      if (isChecked) {
                        addAxisConfig(column, 'yAxis');
                      } else {
                        removeAxisConfig(column, 'yAxis');
                      }
                    }}
                  />
                )
              }
            ]
          };
        })}
      />
    </Container>
  );
};

interface ChartConfiguratorProps {
  chartConfig: ChartConfig;
  chartType: string;
  isLoading?: boolean;
  queryResults: ExecuteQueryResult;
  updateChart: (config: ChartConfig) => void;
}

export const ChartConfigurator = ({
  chartConfig,
  chartType,
  isLoading = false,
  queryResults,
  updateChart
}: ChartConfiguratorProps): ReactElement => {
  const [selectedChartType, setSelectedChartType] = useState<string>(chartType);
  const [xAxisTitle, setXAxisTitle] = useState<string>('');
  const [yAxisTitle, setYAxisTitle] = useState<string>('');

  useEffect(() => {
    if (chartConfig.chartType) {
      setSelectedChartType(chartConfig.chartType as string);
    }
  }, [chartConfig.chartType]);

  const chartTypeSchema = chartMap[chartType]?.data;

  const handleChartTypeChange = (newChartType: string): void => {
    if (newChartType === chartConfig.chartType) {
      return;
    }

    const newConfig = {
      ...chartConfig,
      chartType: newChartType
    };

    updateChart(newConfig);
  };

  return (
    <Container gap="sm" orientation="vertical">
      <Select
        label="Chart type"
        onSelect={handleChartTypeChange}
        value={selectedChartType}
      >
        {/*<Select.Item value="area">Area</Select.Item>*/}
        <Select.Item value="bar">Bar</Select.Item>
        {/*<Select.Item value="hbar">Horizontal bar</Select.Item>*/}
        <Select.Item value="sbar">Stacked bar</Select.Item>
        {/*<Select.Item value="shbar">Stacked horizontal bar</Select.Item>*/}
        <Select.Item value="line">Line</Select.Item>
        {/*<Select.Item value="scatter">Scatter</Select.Item>*/}
        {/*<Select.Item value="pie">Pie</Select.Item>*/}
        {/*<Select.Item value="doughnut">Doughnut</Select.Item>*/}
        {/*<Select.Item value="heatMap">Heatmap</Select.Item>*/}
      </Select>
      <Separator size="md" />
      {/*<TextField
        label="X axis title"
        onChange={setXAxisTitle}
        placeholder="Enter an x-axis label"
        value={xAxisTitle}
      />*/}
      {/*<TextField
        label="Y axis title"
        onChange={setYAxisTitle}
        placeholder="Enter a y-axis label"
        value={yAxisTitle}
      />*/}
      <ColumnSelector
        chartConfig={chartConfig}
        chartTypeSchema={chartTypeSchema}
        queryResults={queryResults}
        updateChart={updateChart}
      />
      <Separator size="md" />
    </Container>
  );
};
